//
// _header.scss
//

#page-topbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1002;
    background: $header-bg;
}

.navbar-brand-box {
    padding: 0 1.5rem;
    width: $navbar-brand-box-width;
    height: 60px;
}

.logo {
    line-height: 60px;

    .logo-sm {
        display: none;
    }
}

.navbar-header {
    flex: 1;
    display: flex;
    justify-content: space-between;
    height: $header-height;
    padding: 0 calc(#{$grid-gutter-width} / 2) 0 0;
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);

    .button-menu-mobile {
        border: none;
        color: $dark;
        display: inline-block;
        height: 60px;
        width: 60px;
        background-color: transparent;
        font-size: 24px;
    }

    .dropdown {
        .show.header-item {
            background-color: $gray-200;
        }
        .flag-img {
            max-width: 25px;
        }
    }
    .dropdown-menu.show:before {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        background: $dropdown-bg;
        top: -6px;
        right: 18px;
        transform: rotate(45deg);
        box-shadow: -2px -4px 0.5rem rgba(108, 118, 134, 0.1);
    }
}

/* Search */
.search-wrap {
    background-color: lighten($card-bg, 4%);
    color: $dark;
    z-index: 9997;
    position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    right: 0;
    height: 60px;
    padding: 0 15px;
    transform: translate3d(0, -100%, 0);
    transition: 0.3s;

    @media (min-width: 992px) {
        width: calc(100% - 240px);
    }

    @media (max-width: 992px) {
        width: calc(100% - 70px);
    }

    @media (max-width: 375px) {
        width: 100%;
    }

    form {
        display: flex;
        width: 100%;
    }
    .search-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .search-input {
        flex: 1 1;
        border: none;
        outline: none;
        box-shadow: none;
        background-color: transparent;
    }
    .close-search {
        width: 36px;
        height: 64px;
        line-height: 64px;
        text-align: center;
        color: inherit;
        font-size: 24px;

        &:hover {
            color: $danger;
        }
    }
}

.search-wrap.open {
    transform: translate3d(0, 0, 0);
}

// Mega menu

.megamenu-list {
    li {
        position: relative;
        padding: 5px 0px;
        a {
            color: $dropdown-color;
        }
    }
}

@media (max-width: 992px) {
    .navbar-brand-box {
        width: auto;
    }

    .logo {
        span.logo-lg {
            display: none;
        }

        span.logo-sm {
            display: inline-block;
        }
    }
}

.page-content {
    padding: calc(#{$header-height} + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2) $footer-height
        calc(#{$grid-gutter-width} / 2);
}

.header-item {
    height: $header-height;
    box-shadow: none !important;
    color: $header-item-color;
    border: 0;
    border-radius: 0px;

    &:hover {
        color: $header-item-color;
    }
}

.header-profile-user {
    height: 30px;
    width: 30px;
    background-color: $gray-300;
}

.noti-icon {
    i {
        font-size: 22px;
        color: $header-item-color;
    }

    .badge {
        position: absolute;
        top: 15px;
        right: 2px;
    }
}

.notify-item {
    img {
        float: right;
        margin-top: 5px;
    }
}

.notification-item {
    .d-flex {
        padding: 0.75rem 1rem;

        &:hover {
            background-color: $gray-200;
        }
    }
}

// Dropdown with Icons
.dropdown-icon-item {
    display: block;
    border-radius: 3px;
    line-height: 34px;
    text-align: center;
    padding: 15px 0 9px;
    display: block;
    border: 1px solid transparent;
    color: $gray-600;

    img {
        height: 24px;
    }

    span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &:hover {
        border-color: $gray-200;
    }
}

body[data-sidebar="colored"] {
    .logo-dark {
        display: none;
    }

    .logo-light {
        display: block;
    }
}

// Light topbar

body[data-topbar="light"] {
    .navbar-header {
        .button-menu-mobile {
            border: none;
            color: $white;
            display: inline-block;
            height: 60px;
            width: 60px;
            background-color: $sidebar-colored-bg;
            font-size: 24px;
        }
        .dropdown {
            .show.header-item {
                background-color: rgba($white, 0.5);
            }
        }

        .waves-effect .waves-ripple {
            background: rgba($white, 0.4);
        }
    }

    .header-item {
        color: $header-dark-item-color;

        &:hover {
            color: $header-dark-item-color;
        }
    }

    .header-profile-user {
        background-color: rgba($white, 0.25);
    }

    .noti-icon {
        i {
            color: $header-dark-item-color;
        }
    }

    .logo-dark {
        display: none;
    }

    .logo-light {
        display: block;
    }

    .app-search {
        .form-control {
            background-color: rgba($topbar-search-bg, 0.07);
            color: $white;
        }
        span,
        input.form-control::-webkit-input-placeholder {
            color: rgba($white, 0.5);
        }
    }
}

body[data-sidebar="light"] {
    #page-topbar {
        background: $sidebar-colored-bg;
    }

    .button-menu-mobile {
        color: $white;
    }

    .logo-dark {
        display: none;
    }

    .logo-light {
        display: block;
    }

    .header-item {
        color: $header-dark-item-color;

        &:hover {
            color: $header-dark-item-color;
        }
    }
    .header-profile-user {
        background-color: rgba($white, 0.25);
    }

    .noti-icon {
        i {
            color: $header-dark-item-color;
        }
    }
}

body[data-topbar="dark"] {
    #page-topbar {
        background-color: $header-dark-bg;
    }
    .navbar-header {
        .button-menu-mobile {
            border: none;
            color: $white;
            display: inline-block;
            height: 60px;
            width: 60px;
            background-color: $header-dark-bg;
            font-size: 24px;
        }
        .dropdown {
            .show.header-item {
                background-color: rgba($white, 0.5);
            }
        }

        .waves-effect .waves-ripple {
            background: rgba($white, 0.4);
        }
    }

    .header-item {
        color: $header-dark-item-color;

        &:hover {
            color: $header-dark-item-color;
        }
    }

    .header-profile-user {
        background-color: rgba($white, 0.25);
    }

    .noti-icon {
        i {
            color: $header-dark-item-color;
        }
    }

    .logo-dark {
        display: none;
    }

    .logo-light {
        display: block;
    }

    .app-search {
        .form-control {
            background-color: rgba($topbar-search-bg, 0.07);
            color: $white;
        }
        span,
        input.form-control::-webkit-input-placeholder {
            color: rgba($white, 0.5);
        }
    }
}

body[data-sidebar="dark"] {
    .navbar-brand-box {
        background: $sidebar-dark-bg;
    }

    .logo-dark {
        display: none;
    }

    .logo-light {
        display: block;
    }
}

@media (max-width: 600px) {
    .navbar-header {
        .dropdown {
            position: static;

            .dropdown-menu {
                left: 10px !important;
                right: 10px !important;
            }
        }
    }
}

@media (max-width: 380px) {
    .navbar-brand-box {
        display: none;
    }
}

body[data-layout="horizontal"] {
    .navbar-header {
        flex: 1;
        display: flex;
        justify-content: space-between;
        height: 50px;
        padding: 0 calc(#{$grid-gutter-width} / 2) 0 0;
        box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    }

    .navbar-brand-box {
        width: auto;
        padding: 0 1.5rem;
        height: 50px;
    }

    .page-content {
        margin-top: 24px;
        padding-top: 0;
    }

    .page-title-box {
        padding: 1.5rem;
    }
}
@media (max-width: 992px) {
    body[data-layout="horizontal"] {
        .page-content {
            margin-top: 12px;
        }
    }
}

@media (min-width: 992px) {
    body[data-layout="horizontal"] {
        .navbar-header {
            padding: 0 calc(#{$grid-gutter-width} / 2);
            height: 50px;

            .header-profile-user {
                height: 30px;
                width: 30px;
            }

            .header-item {
                height: 50px;
            }

            .noti-icon {
                .badge {
                    top: 7px;
                }
            }
        }

        .navbar-brand-box {
            float: left;
            padding-left: 0;

            .logo {
                line-height: 50px;
                margin-right: 70px;
            }
        }
    }
}

@media (max-width: 1024px) {
    body[data-layout="horizontal"] {
        .help-number {
            padding-left: 12px;
        }

        .arrow-down {
            display: none !important;
        }

        .search-wrap {
            width: 100%;
            height: 50px;
        }
    }
}
