//
// _helper.scss
//

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-32 {
  font-size: 32px !important;
}

.font-size-40 {
  font-size: 40px !important;
}

// Font weight help class

.fw-medium {
  font-weight: $font-weight-medium;
}

.fw-semibold {
  font-weight: $font-weight-semibold;
}

.flex-1{
  flex: 1;
}

/* Social */
.social-links {
  li {
    a {
      background: $gray-200;
      border-radius: 50%;
      font-size: 14px;
      color: $gray-600;
      display: inline-block;
      height: 30px;
      line-height: 30px;
      text-align: center;
      width: 30px;
    }
  }
}


.w-xs {
  min-width: 80px;
}
.w-sm {
  min-width: 95px;
}
.w-md {
  min-width: 110px;
}
.w-lg {
  min-width: 140px;
}

.w-xl {
  min-width: 160px;
}

// alert

.alert-dismissible {
  .btn-close{
    font-size: 10px;
    padding: $alert-padding-y * 1.4 $alert-padding-x;
    background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
  }
}