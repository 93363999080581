// 
// authentication.scss
//


  .accountbg {
    position: absolute;
    background-size: cover;
    height: 100%;
    width: 100%;
    top: 0;
  }
  
  .account-page-full {
    left: 0;
    position: absolute;
    height: 100%;
    margin: 0;
    width: 420px;
    background-color: $card-bg;
  
    .card {
      border: none;
    }
  }
  
  .account-copyright {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
// 
// directory
//
  .directory-card {
    overflow: hidden;
  }
  
  .directory-card .directory-content {
    background: $bg-gradient;
}

.directory-card .directory-content .info-icon {
  position: absolute;
  right: -32px;
  background: #ffffff;
  border-radius: 12px 0px 0px 12px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.directory-card .directory-content .info-icon:hover {
  right: 0px;
}
.directory-card .social-links {
  position: relative;
  bottom: 22px;
  margin: 0px 20px;
  background: #ffffff;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
}


.auth-logo{
  &.logo-light{
    display: $display-none;
  }
  &.logo-dark {
    display: $display-block;
  }
}