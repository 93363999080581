/* ==============
  Timeline
===================*/


/* Timeline */
.cd-container {
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
}
.cd-container::after {
  content: '';
  display: table;
  clear: both;
}
#cd-timeline {
  margin-bottom: 2em;
  margin-top: 2em;
  padding: 2em 0;
  position: relative;
  &::before {
    content: '';
    height: 100%;
    left: 18px;
    position: absolute;
    top: 0;
    width: 3px;
  }
}
@media only screen and (min-width: 1170px) {
  #cd-timeline {
    margin-bottom: 3em;
    margin-top: 3em;
    &::before {
      left: 50%;
      margin-left: -2px;
    }
  }
}

.cd-timeline-block {
  margin: 2em 0;
  position: relative;
  &:after {
    clear: both;
    content: "";
    display: table;
  }
}
.cd-timeline-block:first-child {
  margin-top: 0;
}
.cd-timeline-block:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-block {
    margin: 4em 0;
  }
  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
  .timeline-right{
    &::before{
      content: "";
      position: absolute;
      width: 300px;
      height: 150px;
      left: 58%;
      top: 35px;
      border-right: 3px solid $gray-300;
      border-top: 3px solid $gray-300;
      border-radius: 8px;
    }
  }
  .timeline-left{
    &::before{
      content: "";
      position: absolute;
      width: 300px;
      height: 150px;
      left: 14%;
      top: 35px;
      border-left: 3px solid $gray-300;
      border-top: 3px solid $gray-300;
      border-radius: 8px;
    }
    .cd-timeline-img{
      left: 28%;
    }
  }
}

.cd-timeline-img {
  position: absolute;
  top: 16px;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-size: 20px;
  color: $white;
  background-color: $primary;
  border: 5px solid $white;

  i {
    margin-left: 1px;
  }
}

@media only screen and (min-width: 1170px) {
  .cd-timeline-img {
    width: 40px;
    height: 40px;
    line-height: 30px;
    left: 71%;
    margin-left: -20px;
  }
  .timeline-left{
    .cd-timeline-img{
      left: 28%;
    }
  }

}

.cd-timeline-content {
  border-radius: 5px;
  border: 1px solid $light;
  margin-left: 60px;
  padding: 1em;
  position: relative;
  background: $sidebar-colored-bg;

  &:after {
    clear: both;
    content: "";
    display: table;
  }
  .cd-date {
    display: inline-block;
    font-size: 14px;
  }
  h3 {
    font-size: 18px;
    margin: 0 0 15px 0;
  }
}

.cd-timeline-content .cd-date {
  float: right;
  padding: .8em 0;
  opacity: .7;
}
.cd-timeline-content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 12px solid transparent;
  border-right: 12px solid $primary;
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 54%;
    &:before {
      top: 24px;
      left: 100%;
      border-color: transparent;
      border-left-color: $primary;
    }
    .cd-date {
      position: absolute;
      width: 100%;
      left: 87%;
      top: 5px;
    }
    &.last{
      &::before{
        display: none;
      }
    }
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: $primary;
  }

  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
    left: auto;
    right: 6%;
    text-align: right;
  }
}

@media (max-width: 1169px) {
  #cd-timeline{
    &:before{
      border-left: 3px solid $light;
    }
    .cd-timeline-img{
      top: 10px;
    }
  }
}
