/* =============
   Gallery
============= */

.overlay-container{
  position: relative;
}
.project-item img.gallery-thumb-img{
  display: block;
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.project-item-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  border-radius: 5px;
  background: -moz-linear-gradient(top, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  transition: .5s ease;

  h4 {
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
    color: white;
    position: absolute;
    overflow: hidden;
    top: 7%;
    left: 7%;
    margin: 0;
    text-overflow: ellipsis;
  }

  p{
    font-size: 15px;
    font-weight: 600;
    white-space: nowrap;
    color: white;
    position: absolute;
    overflow: hidden;
    bottom: 7%;
    left: 7%;
    text-overflow: ellipsis;
    margin: 0;
  }
}

.overlay-container:hover .project-item-overlay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  height: 100%;
  width: 100%;
  transition: .5s ease;
}
